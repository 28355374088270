import mjrlogo from '../media/itsmarcosjreyes-header-small.svg';
import mjrcodelogo from '../media/itsmarcosjreyes-header-small-code-logo.svg';
import githublogo from '../media/github-logo.svg';
import twitterlogo from '../media/twitter-logo.svg';
import instagramlogo from '../media/instagram-logo.svg';
import tiktoklogo from '../media/tiktok-logo.svg';
import twitchlogo from '../media/twitch-logo.svg';
import youtubelogo from '../media/youtube-logo.svg';
import linkedinlogo from '../media/linkedin-logo.svg';
import mjrengineer from '../media/itsmarcosjreyes-ios-tvos-engineer.png';
import mjrkineticengine from '../media/itsmarcosjreyes-ios-tvos-aws-nodejs-kinetic-app-engine.png';
import mjrwamh from '../media/itsmarcosjreyes-we-all-make-history.png';
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import './App.css';

const MARCOSJREYES_RESUME = './resume/MarcosJReyes-Apple-Sr-Lead-MobileEngineer-Mar2022.pdf';
const WAMH_DISCORD = 'https://discord.gg/3U4xmcmhr2';
const KM_LANDING = 'https://kineticmatrix.io';
const MARCOS_BOOK_ME = 'https://marcosjreyes.simplybook.me/v2/#book';
const bookMarcosJReyes = async () => {
  window.open(MARCOS_BOOK_ME);
};
const goToKMSite = async () => {
  window.open(KM_LANDING);
};
const goToWAMHDiscord = async () => {
  window.open(WAMH_DISCORD);
};

function Home() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className="App">
      <div className="main-container">
      <header className="header-branding-container">
        <img src={mjrlogo} className="header-branding-logo" alt="itsmarcosjreyes" />
        <img src={mjrcodelogo} className="header-branding-logo-code" alt="itsmarcosjreyes" />
        <p className="header-branding-text">Marcos J. Reyes</p>
        <p className="header-branding-subtext">@itsmarcosjreyes</p>

        <div className="typewriter">
          <h3>🚀 Empowering your journey to craft world-changing</h3>
        </div>
        <div className="typewriter-delay">
          <h3>apps and achieve greatness 🏆💪</h3>
        </div>
        <div className="divider-orange"></div>

        <div className="networks-container ">
          <div className="network-item">
            <a href="https://github.com/itsmarcosjreyes" target="_blank" rel="noopener noreferrer">
            <img src={githublogo} className="" alt="GitHub - itsmarcosjreyes" />
            </a>
          </div>
          <div className="network-item">
            <a href="https://www.linkedin.com/in/marcosjreyes/" target="_blank" rel="noopener noreferrer">
            <img src={linkedinlogo} className="" alt="LinkedIn - Marcos Reyes" />
            </a>
          </div>
          <div className="network-item">
            <a href="https://twitter.com/itsmarcosjreyes" target="_blank" rel="noopener noreferrer">
            <img src={twitterlogo} className="" alt="Twitter - @itsmarcosjreyes" />
            </a>
          </div>
          <div className="network-item">
            <a href="https://www.instagram.com/itsmarcosjreyes/" target="_blank" rel="noopener noreferrer">
            <img src={instagramlogo} className="" alt="Instagram - @itsmarcosjreyes" />
            </a>
          </div>
          <div className="network-item">
            <a href="https://www.tiktok.com/@itsmarcosjreyes" target="_blank" rel="noopener noreferrer">
            <img src={tiktoklogo} className="" alt="TikTok - @itsmarcosjreyes" />
            </a>
          </div>
          <div className="network-item">
            <a href="https://www.youtube.com/channel/UCl3yu2zpFd75bomFopNpYng" target="_blank" rel="noopener noreferrer">
            <img src={youtubelogo} className="" alt="YouTube - @itsmarcosjreyes" />
            </a>
          </div>
          <div className="network-item">
            <a href="https://www.twitch.tv/itsmarcosjreyes" target="_blank" rel="noopener noreferrer">
            <img src={twitchlogo} className="" alt="Twitch - @itsmarcosjreyes" />
            </a>
          </div>
          {/* <div className="network-item">
            <img src={venmologo} className="" alt="Venmo - @itsmarcosjreyes" />
          </div>
          <div className="network-item">
            <img src={cashapplogo} className="" alt="CashApp - $marcosreyes311" />
          </div> */}
        </div>

        <div className="divider-orange"></div>
        </header>
        
        <h2 className="section-title linear-wipe">Cool Stuff</h2>
        <div className="cool-project-listing">
          <div className="thirty-percent-width push-left">
            <img src={mjrengineer} className="" alt="Marcos J Reyes - Apple Guru" />
          </div>
          <div className="seventy-percent-width">
            <p className="cool-project-title ">Mobile Engineer :: iOS tvOS watchOS :: Apple TV App of the Year<br/><br/>🚀 Empowering your journey to craft world-changing apps and achieve greatness 🏆💪</p>
            <p className="cool-project-description">
            I've been developing iOS, tvOS, and watchOS apps for 15+ years. I started coding at a young age and co-founded a software engineering company. My proudest moment was being among the first to develop a tvOS app featured in the tvOS App Store. I also built ViX, blim tv and Olympic Channel, OTT streaming apps.<br/><br/>
            I've led engineers to exceed expectations, such as developing ViX, with a subscription model, featured over 10 times in both AppStores, in less than 12 months.<br/><br/>
            <b>ViX was the first LATAM-oriented app to win App of the Year in Apple history!</b><br/><br/>
            I teach engineers how to solve complex projects consistently by writing efficient, scalable, and future-proof code, understanding advanced concepts and avoiding unnecessary complexity. I teach effective methods taking into account business needs and creating actionable plans to stay on the desired engineering direction. I lead sessions to explain the "why" behind what we're doing and explore different ways of approaching problems.
            </p>
          </div>
          <button
            className="cta-button cta-orange-animation"
            onClick={goToKMSite}
          >
            Be the first to know when my course launches!
          </button>
          {/* <Link to="/consulting">
          <button
            className="cta-button cta-teal-animation"
          >
            See what I can do for you
          </button>
          </Link> */}
        </div>

        {/* <div className="divider-orange"></div>

        <div className="cool-project-listing">
          <div className="seventy-percent-width">
            <p className="cool-project-title ">Kinetic App Engine</p>
            <p className="cool-project-description">Launch. Disrupt. Scale. At unmatched speed.<br/><br/>
            The Kinetic App Engine is an all encompassing solution to deploy fully native apps for Apple and Android, while maintaing all your business logic centralized server-side.<br/><br/>
            <b>Control your UX and UI remotely to allow for fast iterations, A/B testing and powerful updates without waiting for review times.</b><br/><br/>
            The Kinetic App Engine also includes a robust serverless ClientApi layer that's easy to modify and augment as needed. This ClientApi can be deployed as part of your infrastructure within minutes!</p>
          </div>
          <div className="thirty-percent-width push-right">
            <img src={mjrkineticengine} className="" alt="Kinetic App Engine" />
          </div>
          <Link to="/kinetic-app-engine">
          <button
            className="cta-button cta-teal-animation"
          >
            Check this out
          </button>
          </Link>
        </div> */}

        {/* <div className="divider-orange"></div>

        <div className="cool-project-listing">
          <div className="thirty-percent-width push-left">
            <img src={mjrwamh} className="" alt="We All Make History NFT" />
          </div>
          <div className="seventy-percent-width">
            <p className="cool-project-title ">We All Make History NFT Project</p>
            <p className="cool-project-description">This is an NFT collection where the proceeds will go towards solving 16 major world wide problems.<br/><br/>
            Every year, I will pick one of the 16 problems to really zone in and help organizations, create new organizations if needed and whatever actionable items need to be executed to actually help correct the problem.<br/><br/>
            As the project gains more traction and people buy and sell their NFTs to help the world, the royalties will be re-invested towards helping solve these problems.<br/><br/>
            Every year, a digital time capsule will be written on the blockchain with the names of all those who helped by being involved with the NFT during that year to make the world a better place.<br/><br/>
            <b>Those time capsules will forever live throughout human history in blockchain.</b></p>
          </div>
          <button
            className="cta-button cta-purple-animation"
            onClick={goToWAMHDiscord}
          >
            Join Discord
          </button>
          <Link to="/we-all-make-history">
          <button
            className="cta-button cta-teal-animation"
          >
            Read more about WAMH!
          </button>
          </Link>
        </div> */}

        <div className="footer">
          {/* <a
            className="footer-text"
            href={MARCOSJREYES_RESUME}
            target="_blank"
            rel="noreferrer"
          >Click here to view my resume</a> */}
          <p>© {new Date().getFullYear()} Marcos J. Reyes. All Rights Reserved.</p>
        </div>
      </div>
    </div>
  );
}

export default Home;
